import React from 'react';

import Pub from '../components/Pub';

function PubPage() {
    return(
        <Pub />
    );
}

export default PubPage;